<template>
	<div class="setting d-flex flex-column">
		<!-- This is the header block -->
		<v-row no-gutters dense class="d-flex flex-grow-0">
			<v-col cols="12">
				<v-row no-gutters dense>
					<v-col cols="12" class="pa-0">
						<v-row no-gutters>
							<v-col cols="12" class="pa-0">
								<p class="my-heading">Settings</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row no-gutters dense class="d-flex">
			<v-col cols="12" class="pa-0">
				<app-user-profile></app-user-profile>
			</v-col>
		</v-row>
		<v-row class="footer d-flex align-end justify-end pa-0 ma-0" cols="12">
			<div>{{ this.version }}</div>
		</v-row>
	</div>
</template>
<script>
const UserProfile = () => import("./UserProfile"); // "./UserProfile";
import { Build_Version } from "../../config/variable";
// import { getSetting } from "../../services/settingservice";
export default {
	components: {
		AppUserProfile: UserProfile,
	},

	data() {
		return {
			version: Build_Version,
			tab: "company_profile"
		};
	},

	// async mounted() {
	// 	await getSetting();
	// },

	methods: {
		changeTab(tab_name) {
			this.$store.commit("settingTab", tab_name);
		}
	}
};
</script>

<style lang="scss" scoped>
.setting {
	height: inherit !important;

	.footer {
		z-index: 9;
		padding-top: 8px !important;
		margin-bottom: -16px !important;
		font-family: Inter-Medium !important;
		font-size: 12px !important;
		color: rgba(79, 91, 109, 0.61);
		text-align: right;
	}
}
</style>
